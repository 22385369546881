import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

//Providers
import ModalProvider from './src/context/Modal/Modal.state'
import TablesProvider from './src/context/Tables/Tables.state'
import VariantProvider from './src/context/Variant/Variant.state'
import RegisterProvider from './src/context/Register/Register.state'

// Style
import './src/styles/index.scss'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY as string}>
    <VariantProvider>
      <ModalProvider>
        <RegisterProvider>
          <TablesProvider>{element}</TablesProvider>
        </RegisterProvider>
      </ModalProvider>
    </VariantProvider>
  </GoogleReCaptchaProvider>
)
