export const UPDATE_LANGUAGE = 'UPDATE_VARIANT'

export type VariantState = {
  variant: string | null
  updateVariant: (variant: string | null) => void
}

export type VariantAction = {
  type: 'UPDATE_VARIANT'
  payload: string | null
}
