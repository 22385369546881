import { ReactNode } from 'react'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export type ModalState = {
  isOpen: boolean
  modal: ReactNode
  unclosable: boolean
  openModal: (component: ReactNode, unclosable?: boolean) => void
  closeModal: () => void
}

export type ModalAction =
  | {
      type: typeof OPEN_MODAL
      payload: ReactNode
      unclosable?: boolean 
    }
  | {
      type: typeof CLOSE_MODAL
    }
