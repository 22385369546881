import { createContext } from 'react'
import { ModalState } from './Modal.types'

const initialState: ModalState = {
  isOpen: false,
  modal: null,
  openModal: () => null,
  closeModal: () => null,
  unclosable: false
}

const ModalContext = createContext<ModalState>(initialState)

export default ModalContext
