import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import { authorName, normalizeText } from '../../utils/utils'

import RegisterContext from './Register.context'
import RegisterReducer from './Register.reducer'
import {
  RegisterState,
  UPDATE_TYPES,
  UPDATE_CITIES,
  UPDATE_STATUS,
  UPDATE_DEM_AGE,
  UPDATE_DEM_SEX,
  UPDATE_AUX_TEAM,
  UPDATE_AUX_GENRE,
  UPDATE_DEPARMENTS,
  UPDATE_COUNTRIES,
  UPDATE_DISTRIBUTIONS,
  UPDATE_SERIES,
  UPDATE_AUTHORS,
  UPDATE_PUBLISHERS,
  UPDATE_PUBLICATIONS,
  UPDATE_GENRES,
  UPDATE_AGE_RANGES,
  UPDATE_WARNINGS,
  UPDATE_VARIANT,
} from './Register.types'

import {
  getCities,
  getDemAge,
  getDemSex,
  getStatuses,
  getCountries,
  getDepartments,
  getDistributions,
  getPublicationTypes,
  getSeriesGenre,
  getWarnings,
  getPublicationTeam,
  getSeries,
  getAuthors,
  getPublishers,
  getPublications,
  getGenres,
  getAgeRanges,
} from '../../services/register'
import { RegisterVariants } from '../../services/types'

interface Props {
  children?: React.ReactNode
}

const RegisterProvider: React.FC<Props> = ({ children }) => {
  let initialState: RegisterState = {
    variant: '',
    types: [],
    cities: [],
    genres: [],
    demAges: [],
    statuses: [],
    demSexes: [],
    warnings: [],
    ageRanges: [],
    countries: [],
    departments: [],
    distributions: [],
    auxTeam: [],
    auxGenres: [],
    series: [],
    authors: [],
    publishers: [],
    publications: [],
    updateVariant: (v:RegisterVariants)=> Promise.resolve(),
    updateTypes: () => Promise.resolve(),
    updateGenres: () => Promise.resolve(),
    updateCities: () => Promise.resolve(),
    updateDemAges: () => Promise.resolve(),
    updateDemSexes: () => Promise.resolve(),
    updateStatuses: () => Promise.resolve(),
    updateWarnings: () => Promise.resolve(),
    updateAgeRanges: () => Promise.resolve(),
    updateCountries: () => Promise.resolve(),
    updateDepartments: () => Promise.resolve(),
    updateDistributions: () => Promise.resolve(),
    updateAuxTeam: () => Promise.resolve(),
    updateAuxGenre: () => Promise.resolve(),
    updateSeries: () => Promise.resolve(),
    updateAuthors: () => Promise.resolve(),
    updatePublishers: () => Promise.resolve(),
    updatePublications: () => Promise.resolve(),
  }

  const [state, dispatch] = useReducer(RegisterReducer, initialState)

  const updateCities = async () => {
    const cities = await getCities()
    const sorted = cities.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_CITIES, payload: sorted })
  }

  const updateDepartments = async () => {
    const departments = await getDepartments()
    const sorted = departments.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_DEPARMENTS, payload: sorted })
  }

  const updateCountries = async () => {
    const countries = await getCountries()
    const sorted = countries.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_COUNTRIES, payload: sorted })
  }

  const updateDistributions = async () => {
    const distributions = await getDistributions()
    const sorted = distributions.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_DISTRIBUTIONS, payload: sorted })
  }

  const updateStatuses = async () => {
    const statuses = await getStatuses()
    const sorted = statuses.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_STATUS, payload: sorted })
  }

  const updateTypes = async () => {
    const types = await getPublicationTypes()
    const sorted = types.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_TYPES, payload: sorted })
  }

  const updateDemAges = async () => {
    const demAges = await getDemAge()
    const sorted = demAges.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_DEM_AGE, payload: sorted })
  }

  const updateDemSexes = async () => {
    const demSexes = await getDemSex()
    const sorted = demSexes.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_DEM_SEX, payload: sorted })
  }

  const updateAuxGenre = async () => {
    const auxGenre = await getSeriesGenre()
    dispatch({ type: UPDATE_AUX_GENRE, payload: auxGenre })
  }

  const updateAuxTeam = async () => {
    const auxTeam = await getPublicationTeam()
    dispatch({ type: UPDATE_AUX_TEAM, payload: auxTeam })
  }

  const updateGenres = async () => {
    const genres = await getGenres()
    dispatch({ type: UPDATE_GENRES, payload: genres })
  }

  const updateAgeRanges = async () => {
    const ageRanges = await getAgeRanges()
    dispatch({ type: UPDATE_AGE_RANGES, payload: ageRanges })
  }

  const updateWarnings = async () => {
    const warnings = await getWarnings()
    dispatch({ type: UPDATE_WARNINGS, payload: warnings })
  }

  const updateSeries = async () => {
    const series = await getSeries()
    const sorted = series.sort((a, b) => {
      if (normalizeText(a.title) < normalizeText(b.title)) return -1
      return 1
    })
    dispatch({ type: UPDATE_SERIES, payload: sorted })
  }

  const updateAuthors = async () => {
    const authors = await getAuthors()
    const sorted = authors.sort((a, b) => {
      if (
        normalizeText(authorName(a.fullname, a.pseudonym)) <
        normalizeText(authorName(b.fullname, b.pseudonym))
      )
        return -1
      return 1
    })
    dispatch({ type: UPDATE_AUTHORS, payload: sorted })
  }

  const updatePublishers = async () => {
    const publishers = await getPublishers()
    const sorted = publishers.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_PUBLISHERS, payload: sorted })
  }

  const updatePublications = async () => {
    const publications = await getPublications()
    const sorted = publications.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_PUBLICATIONS, payload: sorted })
  }

  const updateVariant = async (variant:RegisterVariants) => {
    dispatch({ type: UPDATE_VARIANT, payload: variant })
  }

  return (
    <RegisterContext.Provider
      value={{
        variant: state.variant,
        types: state.types,
        cities: state.cities,
        genres: state.genres,
        demAges: state.demAges,
        demSexes: state.demSexes,
        statuses: state.statuses,
        warnings: state.warnings,
        ageRanges: state.ageRanges,
        countries: state.countries,
        departments: state.departments,
        distributions: state.distributions,
        auxTeam: state.auxTeam,
        auxGenres: state.auxGenres,
        series: state.series,
        authors: state.authors,
        publishers: state.publishers,
        publications: state.publications,
        updateVariant,
        updateTypes,
        updateCities,
        updateGenres,
        updateDemAges,
        updateDemSexes,
        updateStatuses,
        updateWarnings,
        updateCountries,
        updateAgeRanges,
        updateDepartments,
        updateDistributions,
        updateAuxTeam,
        updateAuxGenre,
        updateSeries,
        updateAuthors,
        updatePublishers,
        updatePublications,
      }}
    >
      {children}
    </RegisterContext.Provider>
  )
}

RegisterProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default RegisterProvider
