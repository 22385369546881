import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import { authorName, normalizeText } from '../../utils/utils'

import TablesContext from './Tables.context'
import TablesReducer from './Tables.reducer'
import {
  TablesState,
  UPDATE_SERIES,
  UPDATE_AUTHORS,
  UPDATE_PUBLISHERS,
  UPDATE_PUBLICATIONS,
} from './Tables.types'

import {
  getSeriesExt,
  getAuthorsExt,
  getPublishersExt,
  getPublicationsExt,
} from '../../services/register'

interface Props {
  children?: React.ReactNode
}

const TablesProvider: React.FC<Props> = ({ children }) => {
  let initialState: TablesState = {
    series: [],
    authors: [],
    publishers: [],
    publications: [],
    updateSeries: () => Promise.resolve(),
    updateAuthors: () => Promise.resolve(),
    updatePublishers: () => Promise.resolve(),
    updatePublications: () => Promise.resolve(),
  }

  const [state, dispatch] = useReducer(TablesReducer, initialState)

  const updateSeries = async () => {
    const series = await getSeriesExt()
    const sorted = series.sort((a, b) => {
      if (normalizeText(a.title) < normalizeText(b.title)) return -1
      return 1
    })
    dispatch({ type: UPDATE_SERIES, payload: sorted })
  }

  const updateAuthors = async () => {
    const authors = await getAuthorsExt()
    const sorted = authors.sort((a, b) => {
      if (
        normalizeText(authorName(a.fullname, a.pseudonym)) <
        normalizeText(authorName(b.fullname, b.pseudonym))
      )
        return -1
      return 1
    })
    dispatch({ type: UPDATE_AUTHORS, payload: sorted })
  }

  const updatePublishers = async () => {
    const publishers = await getPublishersExt()
    const sorted = publishers.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_PUBLISHERS, payload: sorted })
  }

  const updatePublications = async () => {
    const publications = await getPublicationsExt()
    const sorted = publications.sort((a, b) => {
      if (normalizeText(a.name) < normalizeText(b.name)) return -1
      return 1
    })
    dispatch({ type: UPDATE_PUBLICATIONS, payload: sorted })
  }

  return (
    <TablesContext.Provider
      value={{
        series: state.series,
        authors: state.authors,
        publishers: state.publishers,
        publications: state.publications,
        updateSeries,
        updateAuthors,
        updatePublishers,
        updatePublications,
      }}
    >
      {children}
    </TablesContext.Provider>
  )
}

TablesProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default TablesProvider
